.container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f5f5f5; /* Light grey background */
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .heading {
    text-align: center;
    color: #d32f2f; /* Red color for heading */
    margin-bottom: 20px;
  }
  
  .form {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .formGroup {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  
  .label {
    font-weight: bold;
    color: #333; /* Dark grey for labels */
  }
  
  .input,
  .select,
  .textarea {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    background-color: #fff;
  }
  
  .textarea {
    resize: vertical;
  }
  
  .submitButton {
    padding: 10px 20px;
    background-color: #d32f2f; /* Red color for button */
    color: #fff;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .submitButton:hover {
    background-color: #b71c1c; /* Darker red on hover */
  }
  
  .contactInfo {
    margin-top: 20px;
    text-align: center;
  }
  
  .email {
    color: #d32f2f; /* Red color for email */
    font-weight: bold;
  }
  
  .socialLinks {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 10px;
  }
  
  .socialLink {
    color: #333; /* Dark grey for social links */
    text-decoration: none;
    transition: color 0.3s ease;
  }
  
  .socialLink:hover {
    color: #d32f2f; /* Red color on hover */
  }
  
  .footer {
    margin-top: 20px;
    text-align: center;
    color: #666; /* Grey color for footer text */
  }