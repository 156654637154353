/* General Styles */
.about-page {
    font-family: "Arial", sans-serif;
    color: #333;
    background-color: #f9f9f9;
    padding: 20px;
  }
  
  .hero-section {
    text-align: center;
    padding: 100px 20px;
    background: linear-gradient(135deg, #ff4d4d, #cc0000);
    color: white;
    border-radius: 10px;
    margin-bottom: 40px;
  }
  
  .hero-section h1 {
    font-size: 3rem;
    margin-bottom: 20px;
  }
  
  .hero-section p {
    font-size: 1.2rem;
    max-width: 800px;
    margin: 0 auto;
  }
  
  /* Section Styles */
  .section {
    margin: 60px 0;
    padding: 20px;
  }
  
  .section h2 {
    text-align: center;
    font-size: 2.5rem;
    color: #cc0000;
    margin-bottom: 40px;
  }
  
  /* Card Styles */
  .card-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
  }
  
  .card {
    background: white;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    max-width: 300px;
    text-align: center;
    transition: transform 0.3s ease;
  }
  
  .card:hover {
    transform: translateY(-10px);
  }
  
  .card h3 {
    font-size: 1.5rem;
    color: #cc0000;
    margin-bottom: 10px;
  }
  
  .card p {
    font-size: 1rem;
    color: #666;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .card-container {
      flex-direction: column;
      align-items: center;
    }
  }